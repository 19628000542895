import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { Agency } from "../../../Models/agencyModel";
import { map } from "rxjs/operators";
import { Heritage } from "../../../Models/heritageModel";


@Injectable({
  providedIn: 'root',
})
export class AgenceService {

  constructor( private firestore: AngularFirestore) {

  }

  getAgenceByEmail(email: string): Observable<{ id: string, etablissement: Agency } | null> {
    return this.firestore
      .collection('agences', ref => ref.where('email', '==', email))
      .get()
      .pipe(
        map((querySnapshot) => {
          if (!querySnapshot.empty) {
            const etablissementDoc = querySnapshot.docs[0];
            const id = etablissementDoc.id;
            const etablissementData = etablissementDoc.data() as Agency;
            return { id, etablissement: etablissementData };
          } else {
            return null;
          }
        })
      );
  }

  getHeritagesByAgenceId(idAgence: string): Observable<Heritage[]> {
    return this.firestore.collection<Heritage>('heritage', ref =>
      ref.where('agenceID', '==', idAgence)
    ).valueChanges();
  }

  getAllHeritages(): Observable<Heritage[]> {
    return this.firestore.collection<Heritage>('heritage').valueChanges();
  }
  getAllagency(): Observable<Agency[]> {
    return this.firestore
      .collection('agences')
      .snapshotChanges() // Obtenir les modifications, y compris les IDs
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Agency;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }
  getAllHeriatge(): Observable<Heritage[]> {
    return this.firestore
      .collection('heritage')
      .snapshotChanges() // Obtenir les modifications, y compris les IDs
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Agency;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }
  getAgenceById2(agenceId: string): Observable<Agency | undefined> {
    return this.firestore.doc<Agency>(`agences/${agenceId}`).valueChanges();
  }
  getAgenceById(eventId: string): Observable<Agency | null> {
    return this.firestore
      .collection('agences')
      .doc(eventId)
      .valueChanges()
      .pipe(
        map((event: any) => { // Spécifiez le type comme "any" ici
          if (event) {
            return { id: eventId, ...event } as Agency;
          } else {
            return null;
          }
        })
      );
}
}
