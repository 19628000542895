import { AngularFireAuth } from './../../../../node_modules/@angular/fire/auth/auth.d';
import { Injectable } from '@angular/core';

import { getFirebaseBackend } from '../../authUtils';

import { User } from '../models/auth.models';
import firebase from 'firebase/app';
@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    user: User;

    constructor(

    ) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }

    getUserRoleByEmail(email: string): Promise<string | null> {
      return firebase.firestore().collection('USER').where('email', '==', email).get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            const userData: UserData = querySnapshot.docs[0].data() as UserData; // Définir le type des données récupérées

            if (userData && userData.role) {
              return userData.role; // Retourner le rôle si présent dans les données
            } else {
              return null; // Rôle manquant dans les données
            }
          } else {
            return null; // Aucun utilisateur trouvé avec cette adresse e-mail
          }
        })
        .catch((error) => {
          console.error('Error fetching user role:', error);
          throw error;
        });
    }


    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
      return getFirebaseBackend().loginUser(email, password).then((response: any) => {
          const user = response;
          return user;
      });
  }

  /**
   * Performs the register
   * @param email email
   * @param password password
   */
  register(email: string, password: string) {
      return getFirebaseBackend().registerUser(email, password).then((response: any) => {
          const user = response;
          return user;
      });
  }

  /**
   * Reset password
   * @param email email
   */
  resetPassword(email: string) {
      return getFirebaseBackend().forgetPassword(email,{ url: window.location.protocol + '//' + window.location.host + '/login' }).then((response: any) => {
          const message = response.data;
          return message;
      });
  }

  /**
   * Logout the user
   */
  logout() {
      // logout the user
      getFirebaseBackend().logout();
  }
}


interface UserData {
  role: string;
}
